<template>
    <CRow>
      <CCol col="12" xl="12">
        <CCard>
          <CCardHeader>
            <CRow>
                <CCol md="5">
                      <h4>Transaction Types</h4>
                </CCol>
                <CCol md="5">
                     <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />
                </CCol>
                <CCol md="2">
                     <b-button variant="primary" to="/createtrstype" >New Type</b-button>
                </CCol>
            </CRow>
          </CCardHeader><br/>
          <CCardBody>
            <CRow>
              <CCol col="12" class="text-left">
                 <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol>
            </CRow>
            <div class="table-responsive table">
              <b-table striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" sort-by="createDate" sort-asc="true">

                <template v-slot:cell(createDate)="{ item }">
                    {{ item.createDate | dateWithTime }}
                </template>

                <template v-slot:cell(actions)="{ item }">
                  <b-dropdown variant="info" text="Actions" size="md">
                    <router-link :to='"/edittrstype/" + item.id' tag="b-dropdown-item" > Edit </router-link>
                    <router-link to="#" exact v-on:click.native="DelTrsType(item.id)" tag="b-dropdown-item"> Delete </router-link>
                  </b-dropdown>
                </template>

              </b-table>
            </div>

            <div>
              <b-row>
                <b-col sm="3">
                   <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
                </b-col>
                <b-col sm="9">
                   <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
                   </b-form-select>
                </b-col>
              </b-row>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </template>

  <script>
  import FraudService from '@/api/FraudService.js';

   export default {
      name: "fraudtrstype",
      data() {
        return {
          spinner:false,
          items:[],
          fields: [
            {
              key: 'code',
              label : 'Code',
              sortable: true
            },
            {
              key: 'name',
              label : 'Name',
              sortable: true
            },
            {
              key: 'description',
              label : 'Description',
              sortable: true
            },
            {
              key: 'keepPastRecordDays',
              label : 'Historical Transactions Horizon (in Days)',
              sortable: true
            },
            {
              key: 'createDate',
              label : 'Created Date',
              sortable: true
            },
            {
              key: 'actions',
              label: 'Actions'
            }
          ],
          currentPage: 1,
          perPage: 10,
          filter: null,
          seen:false,
          msg :'',
          color:''
        };
      },
      filters: {
          upper(value){
            if(!value) return ''
            value = value.toString()
            return value.toUpperCase()
          },
          lower(value){
             if(!value) return ''
             value = value.toString()
             return value.toLowerCase()
          },

          dateSubstr(value){
             if(!value) return ''
             value = value.toString()
             return value.substring(0,10)
          },

          IdSubstr(value){
             if(!value) return ''
             value = value.toString()
             return value.substring(0,5) + '...'
          },
          dateWithTime(value){
             if(!value) return ''
             var temp = value.toString().split(".");
             value = temp[0].replace("T"," ");
             return value;
          },

      },
      created () {
         this.getAllTransactionTypes();
      },
      methods: {

        DelTrsType(id){
          if (confirm('Are you sure you want to remove data ? ')) {
              this.removeTransactionTypeById(id);
          }
        },

        removeTransactionTypeById: function(id) {
          FraudService.removeTransactionTypeById(id).then(resp => {
            if(resp.message=='Success'){
                this.msg   = 'Fraud Transaction Type Successfully Deleted';
                this.color = 'success';
                this.getAllTransactionTypes();
            } else {
                this.msg   = !resp.data.message ? "Fraud Transaction Type Failed Deleted!" : resp.data.message;
                this.color = 'warning';
            }
            this.seen  = true;
            setTimeout( () =>  this.seen=false , 5000);
          }, error => {
            this.spinner = false;
          });
        },

        getAllTransactionTypes: function() {
          FraudService.getAllTransactionTypes().then(resp => {
              this.items = resp;
          }, error => {
            this.spinner = false;
          });
        }

      }
   };

  </script>
  <style>
      .table{ overflow-y:scroll; }
  </style>